import React from 'react';
import Header from './../Header/Header';

const Homepage = () => {
    return (
        <div>
            <Header />
        </div>
    );
};

export default Homepage;
