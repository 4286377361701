import React from 'react';
import './ErrorPage.css';

const ErrorPage = (props) => {
    return (
        <>
            <div className='error-container'>
                <h1>Page Not Found</h1>
            </div>
        </>
    );
};

export default ErrorPage;
