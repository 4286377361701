import React from 'react';

const Accreditation = () => {
    return (
        <div>
            <div className='py-5 bg-light shadow-sm'>
                <h2 className=' text-center p-3 rounded h1'>Accreditation</h2>
                <p className='text-center'>about/accreditation</p>
            </div>
            <div className='container'>
                <div className='text-center py-5'>
                    <h2>Accreditation</h2>
                </div>
            </div>
        </div>
    );
};

export default Accreditation;
