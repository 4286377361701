import React from 'react';
import { Link } from 'react-router-dom';

const IoshMs = () => {
    return (
        <div>
            <div className='py-5 bg-light shadow-sm'>
                <h2 className=' text-center p-3 rounded h1'>
                    IOSH MS <br />
                </h2>
                <p className='text-center'>courses/ioshms</p>
            </div>
            <div className='container'>
                <div className='nebosh-header pt-5 py-5'>
                    <div className='row'>
                        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-5 py-5'>
                            <h5 className='h2 text-center'>IOSH MS</h5>

                            <p className='py-5 text-center'>
                                Institute of Occupational Safety & Health is a
                                global UK based organization for health and
                                safety professionals. Managing safely, the
                                market-leading health and safety course for line
                                managers, is tried, tested and trusted. Designed
                                to provide all managers with the confidence and
                                enthusiasm to apply knowledge to the workplace,
                                this course is unlike any other. BESI Private
                                Limited is providing training for IOSH Managing
                                Safely in Bangladesh and in global grounds.
                                Whether you’re looking for face-to-face or
                                e-learning, we can provide the flexibility you
                                need with our huge network of approved training
                                providers from worldwide.
                            </p>
                        </div>
                    </div>

                    <div className='row m-2'>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 border py-3 pt-3 rounded'>
                            <div className='single-information'>
                                <h4>IOSH Managing Safety</h4>
                                <p>
                                    BESI Private Limited is providing training
                                    for IOSH Managing Safely in Bangladesh and
                                    in global grounds. Whether you’re looking
                                    for face-to-face or e-learning, we can
                                    provide the flexibility you need with our
                                    huge network of approved training providers
                                    from worldwide.
                                </p>
                                <br />
                                {/* 02 */}
                                <div className='details-info'>
                                    <h5>Aim or Purpose(why this course)</h5>
                                    <div className='main-list'>
                                        <ul className='list-group'>
                                            <li className='list-group-item'>
                                                • To improve the safety
                                                awareness culture in your
                                                organization, see your
                                                productivity increase and
                                                enhance your reputation within
                                                your supply chain by embedding
                                                safety and health across the
                                                whole organization.
                                            </li>
                                            <li className='list-group-item'>
                                                • To make people understand
                                                about the importance of safety
                                                at work place and ensures you
                                                understand your own
                                                responsibilities for safety and
                                                health.
                                            </li>
                                            <li className='list-group-item'>
                                                • To encourage employees for
                                                maintaining health and safety
                                                while working.
                                            </li>
                                            <li className='list-group-item'>
                                                • To educate managers and
                                                supervisors to monitor and
                                                control the risk.
                                            </li>
                                            <li className='list-group-item'>
                                                • To ensure you can assess and
                                                control risks and hazards.
                                            </li>
                                            <li className='list-group-item'>
                                                • Enables you to investigate
                                                incidents and empowers you to
                                                measure your own performance.
                                            </li>
                                            <li className='list-group-item'>
                                                • Allows for personal
                                                reflections on good practice.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {/* 02 */}
                                <div className='course-content pt-3'>
                                    <h5>Who can attend?</h5>
                                    <p>
                                        Anyone from any background and
                                        professionals can attend this course.
                                    </p>

                                    <div className='main-list'>
                                        <h5>Course Module:</h5>
                                        <ul className='list-group'>
                                            <li className='list-group-item'>
                                                Module 1: Introducing managing
                                                safely
                                            </li>
                                            <li className='list-group-item'>
                                                Module 2: Assessing risks
                                            </li>
                                            <li className='list-group-item'>
                                                Module 3: Controlling risks
                                            </li>
                                            <li className='list-group-item'>
                                                Module 4: Understanding
                                                responsibilities
                                            </li>
                                            <li className='list-group-item'>
                                                Module 5: Understanding hazards
                                            </li>
                                            <li className='list-group-item'>
                                                Module 6: Investigating
                                                incidents
                                            </li>
                                            <li className='list-group-item'>
                                                Module 7: Measuring Performance
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Col-6 */}

                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 border py-3 pt-3 rounded'>
                            <div className='single-information'>
                                {/* 02 */}
                                <div className='course-content pt-3'>
                                    <h5>Assessment</h5>

                                    <div className='main-list'>
                                        <ul className='list-group'>
                                            <li className='list-group-item'>
                                                • MCQ - 60 marks
                                            </li>
                                            <li className='list-group-item'>
                                                • Risk assessment test – 40
                                                marks
                                            </li>
                                        </ul>
                                    </div>

                                    <div className='main-list pt-3'>
                                        <h5>Certification:</h5>
                                        <ul className='list-group'>
                                            <li className='list-group-item'>
                                                • Certificate from UK
                                            </li>
                                        </ul>
                                    </div>

                                    <div className='main-list pt-3'>
                                        <h5>To get a certificate, one must:</h5>
                                        <ul className='list-group'>
                                            <li className='list-group-item'>
                                                • Attend all the 7 modules
                                            </li>
                                            <li className='list-group-item'>
                                                • Achieve 36 marks out of 60 in
                                                the MCQ.
                                            </li>
                                            <li className='list-group-item'>
                                                • Achieve 23 marks out of 40 in
                                                the risk assessment test
                                            </li>
                                            <li className='list-group-item'>
                                                • Achieve minimum 45 marks in
                                                the total assessment.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='main-list pt-3'>
                                        <h5 className=''>Course Duration</h5>
                                        <ul className='list-group'>
                                            <li className='list-group-item'>
                                                • 5 days course
                                            </li>
                                            <li className='list-group-item'>
                                                • Offline classes
                                            </li>
                                        </ul>
                                    </div>

                                    <div className='main-list pt-3'>
                                        <h5 className=''>
                                            Course Fee (Variable)
                                        </h5>
                                        <ul className='list-group'>
                                            <li className='list-group-item'>
                                                • 15,000/- to 20,000/- (approx.
                                                USD 240)
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='main-list'>
                                        <h5>Why BESI?</h5>
                                        <ul className='list-group'>
                                            <li className='list-group-item'>
                                                • First IOSH Approved Training
                                                Provider
                                            </li>
                                            <li className='list-group-item'>
                                                • Provides high quality animated
                                                graphics and better quality
                                                presentation material.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IoshMs;
